.content {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.embed-container {
  position: relative;
  width: 100%;
  padding-bottom: 125%; /* Default aspect ratio for Instagram posts */
  height: 0;
  overflow: hidden;
  max-width: 500px; /* Set a reasonable max-width for desktop */
  margin: 0 auto;
}

.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (min-width: 768px) {
  .embed-container {
    padding-bottom: 100%; /* Adjust aspect ratio for wider screens */
  }
}

@media (min-width: 1024px) {
  .embed-container {
    padding-bottom: 75%; /* Further adjust aspect ratio for even wider screens */
  }
}

@media (min-width: 1440px) {
  .embed-container {
    padding-bottom: 50%; /* Further adjust aspect ratio for even wider screens */
  }
}
