/* Responsiveness */

/* Extra large devices (large desktops, 1601px and up) */
.react-player {
    height: 500px;
    width: calc((500px * 16) / (9));
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1600px) {
    .react-player {
        height: 450px;
        width: calc((450px * 16) / (9));
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .react-player {
        height: 400px;
        width: calc((400px * 16) / (9));
        position: relative;
        top: 0;
        left: 0;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    .react-player {
        width: 100vw;
        height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

/* Tiny devices (575px and below) */
@media (max-width: 575px) {
    .react-player {
        width: 100vw;
        height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
