.flex {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.newPhoto-button {
  position: absolute;
  bottom: 230px;
  right: 20px;
}

.filter-button {
  position: absolute;
  bottom: 160px;
  right: 20px;
}

.locate-button {
  bottom: 90px;
  right: 20px;
}

/* Override mapbox-gl/dist/mapbox-gl.css*/
.mapboxgl-ctrl-group {
  border-radius: 50%;
}

/* Override mapbox-gl/dist/mapbox-gl.css*/
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("../LocateIcon.svg");
  width: 27px;
  height: 27px;
}

/* Override mapbox-gl/dist/mapbox-gl.css*/
.mapboxgl-ctrl-group button {
  width: 49px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-container {
  position: absolute;
  top: 76px; /* Shift labels down by header height + margin */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  align-items: center;
  z-index: 10;
  pointer-events: auto;
}
