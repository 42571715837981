.svgIcon {
  height: 48px;
  width: 48px;
}

.circle {
  height: 48px;
  width: 48px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px #000000;
}

.disabledIcon {
  opacity: 0.5;
  pointer-events: none; /* Prevents clicking */
}
