.logo {
    margin-top: 5px;
    height: 40px;
}

.MuiToolbar-root {
    min-height: 56px !important;
    height: 56px !important;
}

.header-subtitle .tenant-name {
    font-size: 14px;
    color: #666;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.header-subtitle .map-name {
    font-size: 14px;
    color: #666;
    text-align: center;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}
